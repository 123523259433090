import { createSlice } from "@reduxjs/toolkit";

const ExpiredToken = createSlice({
    name : "expired",
    initialState : new Date(),
    reducers : {
        AddExpiredToken : (state,action) => 
            state = action.payload
    }
})

export default ExpiredToken;