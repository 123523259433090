import { createSlice } from "@reduxjs/toolkit";

const LogitoolkitnRedux = createSlice({
    name : "login",
    initialState : [],
    reducers : {
        addStateLogin : (state,action) => {
            
            state = [...state,action.payload]
        }
    }
})

export default LogitoolkitnRedux;