import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Trans } from "react-i18next";

class Sidebar extends Component {
  state = {};

  toggleMenuState(menuState) {
    if (this.state[menuState]) {
      this.setState({ [menuState]: false });
    } else if (Object.keys(this.state).length === 0) {
      this.setState({ [menuState]: true });
    } else {
      Object.keys(this.state).forEach((i) => {
        this.setState({ [i]: false });
      });
      this.setState({ [menuState]: true });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    document.querySelector("#sidebar").classList.remove("active");
    Object.keys(this.state).forEach((i) => {
      this.setState({ [i]: false });
    });

    
  }

  render() {
    return (
      <nav className="sidebar sidebar-offcanvas" id="sidebar">
        <ul className="nav">
          <li
            className={
              this.isPathActive("/home") ? "nav-item active" : "nav-item"
            }
          >
            <Link className="nav-link" to="/home">
              <span className="menu-title">
                <Trans>Home</Trans>
              </span>
              <i className="mdi mdi-home-circle menu-icon"></i>
            </Link>
          </li>

          <li
            className={
              this.isPathActive("/kirim-paket") ? "nav-item active" : "nav-item"
            }
          >
            <Link className="nav-link" to="/kirim-paket">
              <span className="menu-title">
                <Trans>Kirim paket</Trans>
              </span>
              <i className="mdi mdi-cube-send menu-icon"></i>
            </Link>
          </li>
          <li
            className={
              this.isPathActive("/lacak-paket") ? "nav-item active" : "nav-item"
            }
          >
            <Link className="nav-link" to="/lacak-paket">
              <span className="menu-title">
                <Trans>Lacak paket</Trans>
              </span>
              <i className="mdi mdi-radar menu-icon"></i>
            </Link>
          </li>
          <li
            className={
              this.isPathActive("/paket-ku") ? "nav-item active" : "nav-item"
            }
          >
            <Link className="nav-link" to="/paket-ku">
              <span className="menu-title">
                <Trans>Paket saya</Trans>
              </span>
              <i className="mdi mdi-marker-check menu-icon"></i>
            </Link>
          </li>
          <li
            className={
              this.isPathActive("/laporan-tf") ? "nav-item active" : "nav-item"
            }
          >
            <Link className="nav-link" to="/laporan-tf">
              <span className="menu-title">
                <Trans>Talangan</Trans>
              </span>
              <i className="mdi mdi-wallet menu-icon"></i>
            </Link>
          </li>

          <li
            className={
              this.isPathActive("/setting") ? "nav-item active" : "nav-item"
            }
          >
            <Link className="nav-link" to="/setting">
              <span className="menu-title">
                <Trans>Setting</Trans>
              </span>
              <i className="mdi mdi-cogs menu-icon"></i>
            </Link>
          </li>
          
        </ul>
      </nav>
    );
  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }

  componentDidMount() {
    this.onRouteChanged();
    // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    const body = document.querySelector("body");
    document.querySelectorAll(".sidebar .nav-item").forEach((el) => {
      el.addEventListener("mouseover", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.add("hover-open");
        }
      });
      el.addEventListener("mouseout", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.remove("hover-open");
        }
      });
    });
  }
}

export default withRouter(Sidebar);
