import { configureStore } from "@reduxjs/toolkit";
import LogitoolkitnRedux from "./action/login";
import loginHeader from "./action/loginHeader";
import ExpiredToken from "./action/ExpiredToken";
import BaseUrl from "./action/BaseUrl";

const store = configureStore({
    reducer : {
        login : LogitoolkitnRedux.reducer,
        tokenAkses : loginHeader.reducer,
        expired : ExpiredToken.reducer,
        baseurl : BaseUrl.reducer,
    }
})

store.subscribe(()=>{
    console.log("STC : ",store.getState());
})

export const {addStateLogin} = LogitoolkitnRedux.actions;
export const {addStateTokenAkses} = loginHeader.actions;
export const {AddExpiredToken} = ExpiredToken.actions;
export default store;