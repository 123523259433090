import React, { Component,Suspense,lazy } from 'react';
import { Switch, Route,Redirect } from 'react-router-dom';
import Spinner from '../app/shared/Spinner';


const Login = lazy(() => import('../view/login'));
const Dashboard = lazy(() => import('../view/dashboard'));
const deliveryOrder = lazy(() => import('../view/deliveryOrder'));
const KirimPaket = lazy(() => import('../view/input'));
const EditPaket = lazy(() => import('../view/edit_paket'));
const LacakPaket = lazy(() => import('../view/tracking'));
const PaketSaya = lazy(() => import('../view/paket'));
const Chat = lazy(() => import('../view/chat'));
const Register = lazy(() => import('../view/registerol'));
const RegisterAdmin = lazy(() => import('../view/registeradmin'));
const Laporantf = lazy(() => import('../view/Laporantf'));
const Cashback = lazy(() => import('../view/cashback'));
const Cekongkir = lazy(() => import('../view/cekongkir'));
const LengkapiProfile = lazy(() => import('../view/lengkapiprofile'));
const Setting = lazy(() => import('../view/Setting.js'));

class AppRoutes extends Component {
  render () {
    return (
      <Suspense fallback={<Spinner/>}>
        <Switch>
          <Route exact path="/login" component={ Login } />
          <Route exact path="/login" component={ Login } />
          <Route exact path="/admin/register" component={ RegisterAdmin } />
          <Route exact path="/register" component={ Register } />
          <Route  path="/home" component={ Dashboard } />
          <Route  path="/home" component={ Dashboard } />

          <Route  path="/kirim-paket/minta-jemput" component={ deliveryOrder } />
          <Route  path="/kirim-paket/minta-jemput" component={ deliveryOrder } />
          <Route  path="/kirim-paket/edit/:id" component={ EditPaket } />
          <Route  path="/kirim-paket/:id" component={ KirimPaket } />
          
          <Route  path="/kirim-paket" component={ deliveryOrder } />
          <Route  path="/kirim-paket" component={ deliveryOrder } />
          
          <Route  path="/lacak-paket" component={ LacakPaket } />
          <Route  path="/lacak-paket" component={ LacakPaket } />
          <Route  path="/lacak-paket" component={ LacakPaket } />
          <Route  path="/lacak-paket" component={ LacakPaket } />
          <Route  path="/lacak-paket/:id" component={LacakPaket}/>
          <Route  path="/lacak-paket/:id" component={LacakPaket}/>
           

          <Route  path="/paket-ku" component={ PaketSaya } />
          <Route  path="/paket-ku" component={ PaketSaya } />
          <Route  path="/laporan-tf" component={ Laporantf } />
          <Route  path="/laporan-tf" component={ Laporantf } />
          <Route  path="/chat" component={ Chat } />
          <Route  path="/chat" component={ Chat } />
          <Route  path="/cashback" component={ Cashback } />
          <Route  path="/cek-ongkir" component={ Cekongkir } />
          <Route  path="/lengkapi-profile" component={ LengkapiProfile } />
          <Route  path="/setting" component={ Setting } />
        
          <Redirect to="/home" />
        </Switch>
      </Suspense>
    );
  }
}

export default AppRoutes;