import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import './App.scss';
import AppRoutes from './AppRoutes';
import Navbar from './shared/Navbar';
import Sidebar from './shared/Sidebar';
import SettingsPanel from './shared/SettingsPanel';
import Footer from './shared/Footer';

class App extends Component {

  constructor(props){
    super(props);

    this.state = {
    
        isFullPageLayout: true,
      
    }
  }


  componentDidMount() {
 
    this.onRouteChanged();
  }

  

  componentDidUpdate(prevProps) {

  
    if (this.props.location !== prevProps.location) {
      
      this.onRouteChanged();
    }

   
  }

  

  onRouteChanged() {
    const body = document.querySelector('body');
    
    var title = this.props.location.pathname;

    var str = title;
    
    str = str.toLowerCase().replace(/\b[a-z]/g, function(letter) {
        return letter.toUpperCase();
    });      

    document.title = str.replaceAll('/',' ').replaceAll('-',' ');

    if(this.props.location.pathname === '/layout/RtlLayout') {
      body.classList.add('rtl');

     
    }
    else {
      body.classList.remove('rtl')
      
    }

    window.scrollTo(0, 0);

    const fullPageLayoutRoutes = ['/login','/admin/register'];

    for ( let i = 0; i < fullPageLayoutRoutes.length; i++ ) {


      if (this.props.location.pathname === fullPageLayoutRoutes[i]) {
        this.setState({
          isFullPageLayout: true
        })
        document.querySelector('.page-body-wrapper').classList.add('full-page-wrapper');
        return;
      }else {
        this.setState({
          isFullPageLayout: false
        })
        document.querySelector('.page-body-wrapper').classList.remove('full-page-wrapper');
      }
    }
  }

  render () {
   
    let sidebarComponent = !this.state.isFullPageLayout ? <Sidebar/> : '';
    let SettingsPanelComponent = !this.state.isFullPageLayout ? <SettingsPanel/> : '';
    let footerComponent = !this.state.isFullPageLayout ? <Footer/> : '';
    
   
    return (
      <div className="container-scroller">
        { !this.state.isFullPageLayout ? (<Navbar/>) : '' }
        <div className="container-fluid page-body-wrapper">
          { sidebarComponent }
          <div className="main-panel">
            <div className="content-wrapper">
              <AppRoutes/>
              { SettingsPanelComponent }
            </div>
            { footerComponent }
          </div>
        </div>
      </div>
    );
  }

}

export default withRouter(App);
