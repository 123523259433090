import { createSlice } from "@reduxjs/toolkit";

const LogitoolkitnRedux = createSlice({
    name : "tokenAkses",
    initialState : '',
    reducers : {
        addStateTokenAkses : (state,action) =>
            state = (action.payload)
        
    }
})



export default LogitoolkitnRedux;