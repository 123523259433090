import { createSlice } from "@reduxjs/toolkit";
var URL =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_BASE_URL_DEV
    : process.env.REACT_APP_BASE_URL;

const BaseUrl = createSlice({
    name : "baseurl",
    initialState : URL,
    reducers : {
        ChangeBaseUrl : (state,action) => 
            state = action.payload
    }
})

export default BaseUrl;